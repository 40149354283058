export default function playMapsAnim (el) {
  if (!el) {
    return null
  }

  const attr = 'data-play-maps'
  const els = Array.from(el.querySelectorAll('[data-play-map]'))

  let active = false
  let time = 0
  let last = 0
  let index = 0

  function update () {
    if (!active) {
      return
    }
    const now = Date.now()
    if (last === 0) {
      last = now
    }
    const dt = now - last
    last = now
    time += dt

    if (time > 3000) {
      if (index >= els.length) {
        index = 0
      }
      el.setAttribute(attr, index)
      time = 0
      index++
    }
    if (active) {
      window.requestAnimationFrame(update)
    }
  }

  function start () {
    stop()
    active = true
    window.requestAnimationFrame(update)
  }

  function stop () {
    active = false
    window.cancelAnimationFrame(update)
  }

  el.addEventListener('mouseover', () => stop())
  el.addEventListener('mouseout', () => start())

  return {
    start,
    stop
  }
}
