import React, { useEffect, useRef } from 'react'
import LazyImg from './lazy-img'
import Svg from './svg'
import Html from './html'
import useInViewport from '../hooks/use-in-viewport'
import playBlocksAnim from './play-blocks-anim'
import playBoardAnim from './play-board-anim'
import playMapsAnim from './play-maps-anim'

import block0 from './images/play/block_0.png'
import block1 from './images/play/block_1.png'
import block2 from './images/play/block_2.png'
import block3 from './images/play/block_3.png'
import block4 from './images/play/block_4.png'
import block6 from './images/play/block_6.png'
import block7 from './images/play/block_7.png'

import green from './images/play/green.png'
import red from './images/play/red.png'
import yellow from './images/play/yellow.png'
import blue from './images/play/blue.png'

import board from './images/play/board.png'
import buttonPressed from './images/play/button_pressed.png'
import ledOn from './images/play/led_on.png'

const cubettoSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="523.3" height="428.1" viewBox="0 0 523.3 428.1"><path fill="#E2AD83" d="M95.1 0h428.1v428.1H95.1z"/><path fill="#EBC59C" d="M0 0h428.1v428.1H0z"/><path fill="#E2AD83" d="M272.5 136.2c0 21.5 17.4 38.9 38.9 38.9s38.9-17.4 38.9-38.9-17.4-38.9-38.9-38.9-38.9 17.4-38.9 38.9zm-194.7 0c0 21.5 17.4 38.9 38.9 38.9s38.9-17.4 38.9-38.9-17.4-38.9-38.9-38.9c-21.4 0-38.9 17.4-38.9 38.9z"/><circle fill="#935B4D" cx="155.7" cy="97.3" r="19.5"/><circle fill="#935B4D" cx="272.5" cy="97.3" r="19.5"/><path fill="#935B4D" d="M214.1 173c6.7.1 13.3-2.3 18.4-6.8 5.5-4.9 8.6-12.1 8.6-20.2 0-4.2-3.4-7.5-7.6-7.5-4.2 0-7.5 3.4-7.5 7.5 0 8.8-6.4 11.9-11.9 11.9s-11.9-3.1-11.9-11.9c0-4.2-3.4-7.5-7.5-7.5-4.2 0-7.5 3.4-7.5 7.5-.1 17.7 13.5 27 26.9 27z"/></svg>`

function Cubetto () {
  const ref = useRef()
  const inViewport = useInViewport(ref)

  return (
    <div ref={ref} className={`Play-cubetto ${inViewport ? 'is-visible' : ''}`}>
      <div className='Play-image--cubetto'>
        <Svg>{cubettoSvg}</Svg>
      </div>
    </div>
  )
}

let blocksAnim

function Blocks () {
  const ref = useRef()
  const inViewport = useInViewport(ref)

  const blocks = [block0, block4, block2, block6, block1, block3, block7, block6]

  useEffect(() => {
    blocksAnim = playBlocksAnim(ref.current)
    return () => {
      blocksAnim.stop()
      blocksAnim = null
    }
  }, [ref.current])

  useEffect(() => {
    if (blocksAnim) {
      if (inViewport) {
        blocksAnim.start()
      } else {
        blocksAnim.stop()
      }
    }
  }, [inViewport])

  return (
    <div ref={ref} className='Play-blocks' data-play-blocks='data-play-blocks'>
      {blocks.map((block, index) => (
        <div key={index}><img src={block} alt='block' /></div>
      ))}
    </div>
  )
}

let boardAnim

function Board () {
  const ref = useRef()
  const inViewport = useInViewport(ref)

  useEffect(() => {
    boardAnim = playBoardAnim(ref.current)
    return () => {
      boardAnim.stop()
      boardAnim = null
    }
  }, [ref.current])

  useEffect(() => {
    if (boardAnim) {
      if (inViewport) {
        boardAnim.start()
      } else {
        boardAnim.stop()
      }
    }
  }, [inViewport])

  return (
    <div className='Play-boardWrapper'>
      <div ref={ref} className='Play-board' data-play-board='data-play-board'>
        <img className='Play-boardImg' src={board} alt='board' />
        <img className='Play-boardButton' src={buttonPressed} alt='button' data-board-btn='data-board-btn' />
        <div className='Play-boardBlocks' data-play-board-blocks='data-play-board-blocks'>
          <img className='green' src={green} alt='block' />
          <img className='red' src={red} alt='block' />
          <img className='yellow' src={yellow} alt='block' />
          <img className='blue' src={blue} alt='block' />
          <img className='blue' src={blue} alt='block' />
          <img className='yellow' src={yellow} alt='block' />
          <img className='green' src={green} alt='block' />
          <img className='green' src={green} alt='block' />
        </div>
        <div className='Play-boardLeds' data-play-board-leds='data-play-board-leds'>
          <img src={ledOn} alt='led' />
          <img src={ledOn} alt='led' />
          <img src={ledOn} alt='led' />
          <img src={ledOn} alt='led' />
          <img src={ledOn} alt='led' />
          <img src={ledOn} alt='led' />
          <img src={ledOn} alt='led' />
          <img src={ledOn} alt='led' />
        </div>
      </div>
    </div>
  )
}

let mapsAnim

function Maps ({ item }) {
  const ref = useRef()
  const inViewport = useInViewport(ref)

  useEffect(() => {
    mapsAnim = playMapsAnim(ref.current)
    return () => {
      mapsAnim.stop()
      mapsAnim = null
    }
  }, [ref.current])

  useEffect(() => {
    if (mapsAnim) {
      if (inViewport) {
        mapsAnim.start()
      } else {
        mapsAnim.stop()
      }
    }
  }, [inViewport])

  return (
    <div className='Play-mapsWrapper'>
      <div ref={ref} className='Play-maps' data-play-maps='0'>
        {item.imageItems.map(({ image }) => (
          <div key={image.sourceUrl} data-play-map='data-play-map'>
            <LazyImg
              src={image.sourceUrl}
              alt={image.altText}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default function Play ({ data }) {
  const {
    playTitle,
    playDescription,
    playLinkLabel,
    playLinkUrl,
    playItems
  } = data

  return (
    <section className='Play u-sectionDivider'>
      {playTitle && (
        <h2 className='u-centered u-max-md' style={{ padding: '0 10px'}}>{playTitle}</h2>
      )}
      {playDescription && (
        <Html className='Page-content u-centered u-max-sm-md'>
          {playDescription}
        </Html>
      )}
      <div className='Play-inner' data-play='data-play'>
        <ul className='Play-items'>
          {playItems.map((item, index) => {
            const className = index === playItems.length - 1 ? '' : 'u-sectionDivider u-sectionDivider--sm'
            return (
              <li key={item.type} className={`Play-item ${className}`} data-play-item={index}>
                <div className='Play-description Page-content'>
                  <h3 className='Play-title'>{item.title}</h3>
                  <Html>{item.description}</Html>
                </div>
                <div className={`Play-image Play-image--${item.type}`}>
                  {item.type === 'cubetto' && (
                    <Cubetto />
                  )}
                  {item.type === 'blocks' && (
                    <Blocks />
                  )}
                  {item.type === 'board' && (
                    <Board />
                  )}
                  {item.type === 'maps' && (
                    <Maps item={item} />
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      {playLinkLabel && playLinkUrl && (
        <div className='Play-link'>
          <h3>
            <a className='Button' href={playLinkUrl}>
              {playLinkLabel}
            </a>
          </h3>
        </div>
      )}
    </section>
  )
}
