export default function playBoardAnim (el) {
  if (!el) {
    return null
  }
  const blocksEl = el.querySelector('[data-play-board-blocks]')
  const ledsEl = el.querySelector('[data-play-board-leds]')
  const blocks = Array.from(blocksEl.querySelectorAll('img'))
  const leds = Array.from(ledsEl.querySelectorAll('img'))
  const imgs = blocks
    .map((block, i) => [block, leds[i]])
    .reduce((val, arr) => val.concat(arr))
    .concat([el.querySelector('[data-board-btn]')])

  let active = false
  let time = 0
  let last = 0
  let index = 0

  function start () {
    stop()
    if (el) el.classList.add('is-active')
    window.requestAnimationFrame(update)
    active = true
  }

  function stop () {
    time = 0
    last = 0
    index = 0
    if (el) el.classList.remove('is-active')
    imgs.forEach((img) => {
      if (img) img.classList.remove('is-active')
    })
    window.cancelAnimationFrame(update)
    active = false
  }

  function update () {
    if (!active) {
      return
    }
    const now = Date.now()
    if (last === 0) {
      last = now
    }
    const dt = now - last
    last = now
    time += dt

    if (time > 300) {
      if (index >= imgs.length) {
        index = 0
      }
      if (imgs[index]) {
        imgs[index].classList.add('is-active')
      }
      time = 0
      index++
    }
    if (active) {
      window.requestAnimationFrame(update)
    }
  }

  return {
    start,
    stop
  }
}
