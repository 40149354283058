import React from 'react'
// import Form from './form'
import useOptions from '../hooks/use-options'
import SendInBlue from './sendinblue'

// const fields = [{
//   id: 'first_name',
//   label: 'First name',
//   type: 'text',
//   required: true,
//   custom: false
// }, {
//   id: 'email',
//   label: 'Email address',
//   type: 'email',
//   required: true,
//   custom: false
// }]

export default function NewsLetter () {
  const {
    general: {
      enableNewsletterSignup,
      newsletterTitle,
      newsletterDescription
    }
  } = useOptions()

  if (!enableNewsletterSignup) {
    return null
  }

  return (
    <SendInBlue
      title={newsletterTitle}
      description={newsletterDescription}
      formId='newsletter'
    />
  )
  // return (
  //   <Form
  //     title={newsletterTitle}
  //     description={newsletterDescription}
  //     fields={fields}
  //     tags={['Newsletter']}
  //     formId='newsletter'
  //     successMessage={(
  //       <>
  //         <h2>Thanks for signing up!</h2>
  //         <p>Make sure to <strong>check your inbox or spam folder to confirm</strong> your subscription :-) Without confirming you will miss out on discounts, creative Cubetto ideas, how-to's and more!</p>
  //       </>
  //     )}
  //     formAction='https://manage.kmail-lists.com/subscriptions/subscribe?a=QpgHGS&g=KMHC3P'
  //     submitLabel='Sign up to our mailing list'
  //   />
  // )
}
