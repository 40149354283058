import { useEffect } from 'react'

export default function useScript (src) {
  useEffect(() => {
    let script

    if (src) {
      script = document.createElement('script')
      script.src = src
      script.async = true
      document.body.appendChild(script)
    }

    return () => {
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [src])
}