import setStyle from 'usfl/dom/set-style'
import random from 'usfl/math/random'
import debounce from 'usfl/events/debounce'
import distance from 'usfl/math/distance'
import cerp from 'usfl/math/cerp'

const { sin, cos, abs } = Math

const max = 100
const mu = 0.1
const mul = 0.8

const positions = [{
  x: 3,
  y: 0,
  rotation: 0.4
}, {
  x: 106,
  y: 7,
  rotation: -0.2
}, {
  x: 182,
  y: 4,
  rotation: -0.8
}, {
  x: 57,
  y: 53,
  rotation: 0.15
}, {
  x: 146,
  y: 65,
  rotation: 2.2
}, {
  x: 6,
  y: 110,
  rotation: -0.2
}, {
  x: 88,
  y: 105,
  rotation: 0.4
}, {
  x: 193,
  y: 118,
  rotation: 0.15
}]

const offset = 25
const scale = 1.2

positions.forEach((pos) => {
  pos.x += offset
  pos.y += offset
  pos.x *= scale
  pos.y *= scale
})

function createBlocks (els) {
  const blocks = els.map((el, i) => Object.assign({
    el,
    velocity: {
      x: random(5, 10),
      y: random(5, 10),
      z: random(-0.005, 0.005)
    },
    force: {
      x: 0,
      y: 0
    },
    position: {
      x: 0,
      y: 0
    },
    startPosition: {
      x: 100 + random(-30, 30),
      y: 100 + random(-30, 30)
    },
    counter: random(0, 3)
  }, positions[i]))
  blocks.forEach((block) => {
    // const {el, x, y, rotation, position} = block;
    const { el, rotation, position, startPosition } = block
    const { x, y } = startPosition
    position.x = x
    position.y = y
    setStyle(el, {
      transform: `translate(${x}px, ${y}px) rotate(${rotation}rad)`
    })
  })
  return blocks
}

function updateBlock (block, finger, touching) {
  const { el, x, y, rotation, velocity, force, position } = block

  const dist = distance(finger.x, finger.y, position.x, position.y)
  if (touching && abs(dist) < max) {
    const dx = position.x - finger.x
    const dy = position.y - finger.y
    const fx = abs(dx) > max ? 0 : dx < 0 ? 0 - max + dx : max - dx
    const fy = abs(dy) > max ? 0 : dy < 0 ? 0 - max + dy : max - dy
    force.x = fx * mu
    force.y = fy * mu
  } else {
    // force.x *= 0.5;
    // force.y *= 0.5;
    force.x *= 0.98
    force.y *= 0.98
  }

  const amt = 0.2
  let targetX

  if (abs(force.x) > 0.01) {
    targetX = position.x + force.x
    force.x *= mul
  } else {
    targetX = x + cos(block.counter) * velocity.x
    // amt = 0.1;
  }

  let targetY

  if (abs(force.y) > 0.01) {
    targetY = position.y + force.y
    force.y *= mul
  } else {
    targetY = y + sin(block.counter) * velocity.y
    // amt = 0.1;
  }
  block.counter += 0.01

  position.x = cerp(position.x, targetX, amt)
  position.y = cerp(position.y, targetY, amt)

  block.rotation += velocity.z

  setStyle(el, {
    transform: `translate(${position.x}px, ${position.y}px) rotate(${rotation}rad)`
  })
}

function resetBlocks (blocks) {
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i]
    const { el, startPosition, position, rotation } = block
    const { x, y } = startPosition
    position.x = x
    position.y = y
    setStyle(el, {
      transform: `translate(${x}px, ${y}px) rotate(${rotation}rad)`
    })
  }
}

export default function PlayBlocksAnim (el) {
  if (!el) {
    return null
  }
  const els = Array.from(el.querySelectorAll('div'))
  const blocks = createBlocks(els)

  let active = false
  let touching = true

  const finger = {
    x: -100,
    y: -100
  }

  const move = debounce((event) => {
    if (event.touches) {
      event = event.touches[0]
      document.body.removeEventListener('mousemove', move)
    }
    const rect = el.getBoundingClientRect()
    finger.x = (event.clientX || event.pageX) - rect.left
    finger.y = (event.clientY || event.pageY) - rect.top
  })

  function onTouchStart () {
    touching = true
  }

  function onTouchEnd () {
    touching = false
  }

  function start () {
    stop()

    document.body.addEventListener('mousemove', move)
    document.body.addEventListener('touchstart', onTouchStart)
    document.body.addEventListener('touchmove', move)
    document.body.addEventListener('touchend', onTouchEnd)

    window.requestAnimationFrame(update)
    active = true
  }

  function stop () {
    document.body.removeEventListener('mousemove', move)
    document.body.removeEventListener('touchstart', onTouchStart)
    document.body.removeEventListener('touchmove', move)
    document.body.removeEventListener('touchend', onTouchEnd)

    resetBlocks(blocks)
    window.cancelAnimationFrame(update)
    active = false
  }

  function update () {
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i]
      updateBlock(block, finger, touching)
    }
    if (active) {
      window.requestAnimationFrame(update)
    }
  }

  return {
    start,
    stop
  }
}
