import { useEffect, useState } from 'react'

export default function useInViewport (ref) {
  const [inViewport, setInViewport] = useState(false)

  useEffect(() => {
    let observer
    if (ref.current && window.IntersectionObserver) {
      observer = new window.IntersectionObserver(function onChange (entries, observer) {
        entries.forEach(function (entry) {
          setInViewport(entry.isIntersecting)
        })
      })
      observer.observe(ref.current)
    }

    return () => {
      if (observer) {
        observer.unobserve(ref.current)
        observer = null
      }
    }
  }, [ref.current])

  return inViewport
}
